import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import '../assets/css/main.css';
import '../assets/js/main'


function Gallery() {
  return (
<section id="gallery" className="gallery section">

      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Gallery</h2>
        <p>Take a virtual tour of our hospital and facilities. See photos of our infrastructure, medical equipment, and the welcoming environment we provide for our patients and their families.</p>
      </div>

      <div className="container-fluid" data-aos="fade-up" data-aos-delay="100">

        <div className="row g-0">

          <div className="col-lg-3 col-md-4">
            <div className="gallery-item">
              <a href={require("../assets/img/gallery/gallery-1.jpg")} className="glightbox" data-gallery="images-gallery">
                <img src={require("../assets/img/gallery/gallery-1.jpg")} alt="" className="img-fluid"/>
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <div className="gallery-item">
              <a href={require("../assets/img/gallery/gallery-2.jpg")} className="glightbox" data-gallery="images-gallery">
                <img src={require("../assets/img/gallery/gallery-2.jpg")} alt="" className="img-fluid"/>
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <div className="gallery-item">
              <a href={require("../assets/img/gallery/gallery-3.jpg")} className="glightbox" data-gallery="images-gallery">
                <img src={require("../assets/img/gallery/gallery-3.jpg")} alt="" className="img-fluid"/>
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <div className="gallery-item">
              <a href={require("../assets/img/gallery/gallery-4.jpg")} className="glightbox" data-gallery="images-gallery">
                <img src={require("../assets/img/gallery/gallery-4.jpg")} alt="" className="img-fluid"/>
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <div className="gallery-item">
              <a href={require("../assets/img/gallery/gallery-5.jpg")} className="glightbox" data-gallery="images-gallery">
                <img src={require("../assets/img/gallery/gallery-5.jpg")} alt="" className="img-fluid"/>
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <div className="gallery-item">
              <a href={require("../assets/img/gallery/gallery-6.jpg")} className="glightbox" data-gallery="images-gallery">
                <img src={require("../assets/img/gallery/gallery-6.jpg")} alt="" className="img-fluid"/>
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <div className="gallery-item">
              <a href={require("../assets/img/gallery/gallery-7.jpg")} className="glightbox" data-gallery="images-gallery">
                <img src={require("../assets/img/gallery/gallery-7.jpg")} alt="" className="img-fluid"/>
              </a>
            </div>
          </div>

          <div className="col-lg-3 col-md-4">
            <div className="gallery-item">
              <a href={require("../assets/img/gallery/gallery-8.jpg")} className="glightbox" data-gallery="images-gallery">
                <img src={require("../assets/img/gallery/gallery-8.jpg")} alt="" className="img-fluid"/>
              </a>
            </div>
          </div>

        </div>

      </div>

    </section>)}


export default Gallery;