import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/main.css";
import "../assets/js/main";
import { Telephone, GeoAlt, Envelope } from "react-bootstrap-icons";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Contact() {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [open, setOpen] = useState(true);
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const contactParams = {
      from_name: inputs.name,
      from_email: inputs.email,
      reply_to: inputs.email,
      subject: inputs.subject,
      message: inputs.message,
    };

    emailjs
      .send(
        "service_0nbe08u",
        "template_44bi7v8",
        contactParams,
        "Ygq8wXTHKCNxYTrAL"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setEmailSent(true);
          setEmailFailed(false);
          setOpen(true);
          form.current.reset();
          setInputs({});
          toast("Message sent successfully!")
        },
        (err) => {
          console.log("FAILED...", err);
          setEmailSent(false);
          setEmailFailed(true);
          setOpen(true);
          toast("Message sending failed!")
        }
      );
  };

  return (
    <section id="contact" className="contact section">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Contact</h2>
        <p>
          Get in touch with us for any inquiries or to schedule an appointment. We look forward to assisting you with all your healthcare needs.
        </p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-4">
            <div
              className="info-item d-flex"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <i className="bi bi-geo-alt flex-shrink-0">
                <GeoAlt />
              </i>
              <div>
                <h3>Location</h3>
                <p>
                No 1, Ogbeide Medical Centre Avenue, <br/>Idumogho Quarters, Uhe, P. O. Box 406, Ubiaja, <br/>Esan South East LGA, Edo State, Nigeria
                </p>
              </div>
            </div>

            <div
              className="info-item d-flex"
              data-aos="fade-up"
              data-aos-delay="400"
            >
              <i className="bi bi-telephone flex-shrink-0">
                <Telephone />
              </i>
              <div>
                <h3>Call Us</h3>
                <p>
                  <a href="tel:+2347049897403">+234 704 989 7403</a>
                </p>
              </div>
            </div>

            <div
              className="info-item d-flex"
              data-aos="fade-up"
              data-aos-delay="500"
            >
              <i className="bi bi-envelope flex-shrink-0">
                <Envelope />
              </i>
              <div>
                <h3>Email Us</h3>
                <p>
                  <a href="mailto:doctor@ogbeidemedical.com">
                    doctor@ogbeidemedical.com
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-8">
            <form
              ref={form}
              onSubmit={handleSubmit}
              className="php-email-form"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="row gy-4">
                <div className="col-md-6">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Your Name"
                    value={inputs.name || ""}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-6">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    placeholder="Your Email"
                    value={inputs.email || ""}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control"
                    name="subject"
                    placeholder="Subject"
                    value={inputs.subject || ""}
                    onChange={handleChange}
                    required
                  />
                </div>

                <div className="col-md-12">
                  <textarea
                    className="form-control"
                    name="message"
                    rows="6"
                    placeholder="Message"
                    value={inputs.message || ""}
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>

                <div className="col-md-12 text-center">
                  <div className="loading">Loading</div>
                                    
                    {emailSent && (
                      // <div className="sent-message">
                      // <ToastContainer />
                      <Collapse in={open}>
                        <Alert
                          severity="success"
                          onClose={() => {
                            setOpen(false);
                          }}
                        >
                          Your message has been sent. Thank you!
                        </Alert>
                      </Collapse>
                      // </div>
                    )}
                    {emailFailed && (
                      // <div className="error-message">
                      // <ToastContainer />
                      <Collapse in={open}>
                        <Alert
                          severity="error"
                          onClose={() => {
                            setOpen(false);
                          }}
                        >
                          Failed to send your message. Please try again later.
                        </Alert>
                      </Collapse>
                      // </div>
                    )}
                  

                  <button type="submit">Send Message</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
