import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import '../assets/css/main.css';
import '../assets/js/main'
import { TwitterX, Facebook, Instagram, Linkedin, PhoneFill, EnvelopeFill } from "react-bootstrap-icons";

function Topbar() {

  return (
<div className="topbar d-flex align-items-center">
      <div className="container d-flex justify-content-center justify-content-md-between">
        <div className="contact-info d-flex align-items-center">
          <i className="bi bi-envelope d-flex align-items-center"><EnvelopeFill/><a href="mailto:doctor@ogbeidemedical.com">doctor@ogbeidemedical.com</a></i>
          <i className="bi bi-phone d-flex align-items-center ms-4"><PhoneFill/><a href="tel:+2347049897403"><span>+234 704 989 7403</span></a></i>
        </div>
        {/* <div className="social-links d-none d-md-flex align-items-center">
          <a href="#" className="twitter"><i className="bi bi-twitter-x"><TwitterX /></i></a>
          <a href="#" className="facebook"><i className="bi bi-facebook"><Facebook /></i></a>
          <a href="#" className="instagram"><i className="bi bi-instagram"><Instagram /></i></a>
          <a href="#" className="linkedin"><i className="bi bi-linkedin"><Linkedin /></i></a>
        </div> */}
      </div>
    </div>
  )}

  export default Topbar;