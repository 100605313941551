import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import '../assets/css/main.css';
import '../assets/js/main'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartbeat, faPills, faHospitalUser, faDna, faWheelchair, faNotesMedical } from '@fortawesome/free-solid-svg-icons'

function Services() {
  return (
<section id="services" className="services section">

      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Services</h2>
        <p>We offer a comprehensive range of medical services to meet the needs of our community. From primary care and emergency services to specialized treatments and preventive care, our dedicated team is here to provide you with the highest quality of care.</p>
      </div>

      <div className="container">

        <div className="row gy-4">

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div className="service-item  position-relative">
              <div className="icon">
                <i className="fas fa-heartbeat"><FontAwesomeIcon icon={faHeartbeat} /></i>
              </div>
              <a href="#" className="stretched-link">
                <h3>General Pediatrics</h3>
              </a>
              <p>We understand the unique healthcare needs of children and adolescents. Our pediatric team is dedicated to providing high-quality, compassionate care for your little ones, from routine wellness visits and vaccinations to managing illnesses and developmental concerns.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="200">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="fas fa-pills"><FontAwesomeIcon icon={faPills} /></i>
              </div>
              <a href="#" className="stretched-link">
                <h3>Laboratory Services</h3>
              </a>
              <p>Our state-of-the-art laboratory is equipped to perform a wide variety of tests to aid in the diagnosis and management of medical conditions. From blood work to specialized testing, our lab services are essential in providing accurate and timely results for effective treatment plans. X-Ray, Scan, E. C. G.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="300">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="fas fa-hospital-user"><FontAwesomeIcon icon={faHospitalUser} /></i>
              </div>
              <a href="#" className="stretched-link">
                <h3>Metabolic Disease/Diabetes Care</h3>
              </a>
              <p>Managing metabolic diseases, especially diabetes, requires specialized care. Our dedicated team of endocrinologists and diabetes educators work together to provide personalized treatment plans, education, and support to help you manage your condition effectively and improve your quality of life.</p>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="400">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="fas fa-dna"><FontAwesomeIcon icon={faDna} /></i>
              </div>
              <a href="#" className="stretched-link">
                <h3>General Medical Care & Surgery</h3>
              </a>
              <p>We provide comprehensive general medical care for patients of all ages. Our skilled physicians are equipped to handle routine check-ups, diagnosis, and treatment of acute and chronic conditions. Our surgical team offers a wide range of procedures, ensuring you receive the necessary care with precision and compassion.</p>
              <a href="#" className="stretched-link"></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="500">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="fas fa-wheelchair"><FontAwesomeIcon icon={faWheelchair} /></i>
              </div>
              <a href="#" className="stretched-link">
                <h3>Mortuary Services</h3>
              </a>
              <p>In times of loss, our mortuary services provide compassionate and respectful care for your loved ones. Our facility is equipped to handle all aspects of funeral preparation and arrangements with dignity and sensitivity, ensuring families have the support and space they need during difficult times.</p>
              <a href="#" className="stretched-link"></a>
            </div>
          </div>

          <div className="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="600">
            <div className="service-item position-relative">
              <div className="icon">
                <i className="fas fa-notes-medical"><FontAwesomeIcon icon={faNotesMedical} /></i>
              </div>
              <a href="#" className="stretched-link">
                <h3>General Obstetric Care</h3>
              </a>
              <p>We are committed to providing comprehensive obstetric care for expectant mothers. Our services include prenatal care, labor and delivery, and postpartum support. Our experienced obstetricians and midwives are here to ensure a safe and healthy pregnancy and delivery for both mother and baby.</p>
              <a href="#" className="stretched-link"></a>
            </div>
          </div>

        </div>

      </div>

    </section>)}


export default Services;