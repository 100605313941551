import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/js/bootstrap.bundle'
import "../assets/css/main.css";
import "../assets/js/main";
import Topbar from "./Topbar";
import { ArrowUpShort, List, X } from "react-bootstrap-icons";
import React, { useEffect, useState } from "react";

function Header() {
  const [scrolled, setScrolled] = useState(false);
  const [mobileNavActive, setMobileNavActive] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const handleScroll = () => {
      const selectBody = document.querySelector("body");
      const selectHeader = document.querySelector("#header");
      if (
        !selectHeader.classList.contains("scroll-up-sticky") &&
        !selectHeader.classList.contains("sticky-top") &&
        !selectHeader.classList.contains("fixed-top")
      )
        return;
      window.scrollY > 100
        ? selectBody.classList.add("scrolled")
        : selectBody.classList.remove("scrolled");
      setScrolled(window.scrollY > 100);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("load", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("load", handleScroll);
    };
  }, []);

  const toggleMobileNav = () => {
    document.querySelector('body').classList.toggle('mobile-nav-active');
    setMobileNavActive(!mobileNavActive);
  };

  const handleLinkClick = () => {
    // return () => {
      if (mobileNavActive) {
        toggleMobileNav();
        // setActiveIndex(activeIndex === index ? null : index);
      }
    // };
  };

  const handleDropdownClick = (e) => {
    if (mobileNavActive) {
      e.preventDefault();
      e.currentTarget.parentNode.classList.toggle("active");
      e.currentTarget.parentNode.nextElementSibling.classList.toggle(
        "dropdown-active"
      );
    }
  };

  const scrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <header
      id="header"
      className={`header sticky-top ${scrolled ? "scrolled" : ""}`}
    >
      <Topbar />
      <div className="branding d-flex align-items-center">
        <div className="container position-relative d-flex align-items-center justify-content-between">
          <a
            href="index.html"
            className="logo d-flex align-items-center me-auto"
          >
            <img src={require("../assets/img/medicine-logo.png")} alt="Logo" />
            <h1 className="sitename">Ogbeide Medical</h1>
          </a>

          <nav
            id="navmenu"
            className={`navmenu ${mobileNavActive ? "mobile-nav-active" : ""}`}
          >
            <ul>
              <li>
                <a href="#hero" className={activeIndex === 0 ? "active" : ""}  onClick={()=>{setActiveIndex(0);
                  handleLinkClick();}}>
                  Home
                </a>
              </li>
              <li>
                <a href="#about" className={activeIndex === 1 ? "active" : ""}  onClick={()=>{setActiveIndex(1);
                  handleLinkClick();}}>
                  About
                </a>
              </li>
              <li>
                <a href="#services" className={activeIndex === 2 ? "active" : ""}  
                onClick={()=>{setActiveIndex(2);
                  handleLinkClick();}}>
                  Services
                </a>
              </li>
              <li>
                <a href="#departments" className={activeIndex === 3 ? "active" : ""}  onClick={()=>{setActiveIndex(3);
                  handleLinkClick();}}>
                  Departments
                </a>
              </li>
              <li>
                <a href="#doctors" className={activeIndex === 4 ? "active" : ""}  onClick={()=>{setActiveIndex(4);
                  handleLinkClick();}}>
                  Meet our Team
                </a>
              </li>
              {/* <li className="dropdown">
                <a href="#" onClick={handleDropdownClick}>
                  <span>Dropdown</span>
                  <i className="bi bi-chevron-down toggle-dropdown"></i>
                </a>
                <ul>
                  <li>
                    <a href="#">Dropdown 1</a>
                  </li>
                  <li className="dropdown">
                    <a href="#" onClick={handleDropdownClick}>
                      <span>Deep Dropdown</span>
                      <i className="bi bi-chevron-down toggle-dropdown"></i>
                    </a>
                    <ul>
                      <li>
                        <a href="#">Deep Dropdown 1</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 2</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 3</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 4</a>
                      </li>
                      <li>
                        <a href="#">Deep Dropdown 5</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a href="#">Dropdown 2</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 3</a>
                  </li>
                  <li>
                    <a href="#">Dropdown 4</a>
                  </li>
                </ul>
              </li> */}
              <li>
                <a href="#contact" className={activeIndex === 5 ? "active" : ""}  onClick={()=>{setActiveIndex(5);
                  handleLinkClick();}}>
                  Contact
                </a>
              </li>
              <li>
              <a className="cta-btn d-sm-block" href="#appointment" onClick={()=>{setActiveIndex(6);
                  handleLinkClick();}}>
                Make an Appointment
              </a>
              </li>
            </ul>
            <i
              className="mobile-nav-toggle d-xl-none"
              onClick={toggleMobileNav}
              
            >{mobileNavActive ? <X/> : <List/>}</i>
          </nav>

          
        </div>
      </div>
      <a href="#"
      id="scroll-top"
        className={`scroll-top d-flex align-items-center justify-content-center ${scrolled ? "active" : ""}`}
        onClick={scrollToTop}
      ><i className="bi bi-arrow-up-short">
        <ArrowUpShort/></i>
      </a>
    </header>
  );
}

export default Header;
