import Header from "../components/Header";
import Hero from "../components/Hero";
import Services from "../components/Services";
import About from "../components/About";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import Doctors from "../components/Doctors";
import Stats from "../components/Stats";
import Testimonials from "../components/Testimonials";
import Faq from "../components/Faq";
import Departmemts from "../components/Departments";
import Gallery from "../components/Gallery";
import Appointment from "../components/Appointment";
import { useEffect, useState } from "react";
import Preloader from "../components/Preloader";

function Home() {

  return (
    <>
    {/* <Preloader /> */}
      <Header />
      <main>
        <Hero />
        <About />
        <Stats />
        <Services />
        <Appointment />
        <Departmemts />
        <Doctors />
        {/* <Testimonials /> */}
        <Faq />
        <Gallery />
        <Contact />
      </main>
      <Footer />
    </>
  );
}

export default Home;
