import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import '../assets/css/main.css';
import '../assets/js/main'
import { useEffect, useState } from 'react';


function Hero() {
  const [isMobile, setIsMobile] = useState(false);
  const getBackgroundSize = (width, height) => {
    if (width >= 1025 || (width >= 768 && width <= 1024 && width > height)) {
      console.log("desktop");
      setIsMobile(false);
      return "desktop";
    } else if (width >= 768 && width <= 1024 && height > width) {
      console.log("tablet");
      setIsMobile(true);
      return "tablet";
    } else if ((width >= 1 && width <= 480) || (width >= 481 && width <= 767)) {
      console.log("mobile");
      setIsMobile(true);
      return "mobile";
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Perform actions on window resize
      getBackgroundSize(visualViewport.width, visualViewport.height);
      console.log(
        getBackgroundSize(visualViewport.width, visualViewport.height)
      );
    };
    window.addEventListener("resize", handleResize);
    window.addEventListener("load", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("load", handleResize);
    };
  });
  return (
<section id="hero" className="hero section">
  <picture>
    <source srcSet={require("../assets/img/hero-bg.png")} media='(min-width: 1024px)'/>
    <img fetchpriority="high" src={require("../assets/img/hero-bg-portrait.jpg")} alt="" data-aos="fade-in"/>
  </picture>
        {/* {isMobile ? 
        <img fetchpriority="high" src={require("../assets/img/hero-bg-portrait.jpg")} alt="" data-aos="fade-in"/>:
        <img src={require("../assets/img/hero-bg.png")} alt="" data-aos="fade-in"/>} */}
      

      <div className="container position-relative">

        <div className="welcome position-relative" data-aos="fade-down" data-aos-delay="100">
          {isMobile ? 
          <><h2 className='text-white'>WELCOME TO <br/>OGBEIDE MEDICAL CENTRE</h2>
          <p className='text-white'>Quality Health Care Services you can trust</p></>
          :
          <><h2>WELCOME TO <br/>OGBEIDE MEDICAL CENTRE</h2>
          <p>Quality Health Care Services you can trust</p></>
          }
        </div>

        <div className="content row gy-4">
          <div className="col-lg-4 d-flex align-items-stretch">
            <div className="why-box" data-aos="zoom-out" data-aos-delay="200">
              <h3>Why Choose Us?</h3>
              <p>
              We offer a wide range of medical services to meet the needs of our rural community, from primary care to specialized treatments.
              </p>
              {/* <div className="text-center">
                <a href="#about" className="more-btn"><span>Learn More</span> <i className="bi bi-chevron-right"></i></a>
              </div> */}
            </div>
          </div>

          <div className="col-lg-8 d-flex align-items-stretch">
            <div className="d-flex flex-column justify-content-center">
              <div className="row gy-4">

                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box" data-aos="zoom-out" data-aos-delay="300">
                    <i className="bi bi-clipboard-data"></i>
                    <h4>Experienced and Compassionate Staff</h4>
                    <p>Our team of doctors, nurses, and healthcare professionals are dedicated to providing high-quality, personalized care.</p>
                  </div>
                </div>

                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box" data-aos="zoom-out" data-aos-delay="400">
                    <i className="bi bi-gem"></i>
                    <h4>State-of-the-Art Facilities</h4>
                    <p>Our hospital is equipped with modern technology and facilities to ensure you receive the best possible care.</p>
                  </div>
                </div>

                <div className="col-xl-4 d-flex align-items-stretch">
                  <div className="icon-box" data-aos="zoom-out" data-aos-delay="500">
                    <i className="bi bi-inboxes"></i>
                    <h4>Community-Focused Care</h4>
                    <p>We are deeply committed to serving our community, offering programs and services that promote health and well-being for all residents.</p>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </section>)}

export default Hero;