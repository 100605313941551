import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/main.css";
import "../assets/js/main";

function Departments() {
  return (
    <section id="departments" className="departments section">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Departments</h2>
        <p>
        Our specialized departments ensure focused and expert care for all your health needs. Explore our departments to learn more about the comprehensive services we offer, from pediatrics and obstetrics to surgery and community medicine.
        </p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row">
          <div className="col-lg-3">
            <ul className="nav nav-tabs flex-column">
            <li className="nav-item">
                <a
                  className="nav-link active show"
                  data-bs-toggle="tab"
                  href="#departments-tab-1"
                >
                  Administrative
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-2"
                >
                  Radio Diagnostic
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-3"
                >
                  Antenatal
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-4"
                >
                  Postnatal
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-5"
                >
                  Child Health
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-6"
                >
                  Surgical
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-7"
                >
                  Family Health
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-8"
                >
                  Obstetrics and Gynecology
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-9"
                >
                  Immunization
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-10"
                >
                  Pathology
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-11"
                >
                  Mortuary
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-12"
                >
                  Community Medicine
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-13"
                >
                  Laboratory
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-bs-toggle="tab"
                  href="#departments-tab-14"
                >
                  Pharmacy
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-9 mt-4 mt-lg-0">
            <div className="tab-content">
            <div className="tab-pane active show" id="departments-tab-1">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Administrative Department</h3>
                    <p className="fst-italic">
                      Our Laboratory Department provides essential diagnostic
                      testing and analysis, supporting accurate diagnosis and
                      treatment planning. Equipped with advanced technology, we
                      offer a wide range of tests from routine blood work to
                      specialized diagnostic procedures.
                    </p>
                    <p>
                      Our Laboratory Department is the backbone of our
                      diagnostic services, providing a wide array of tests
                      including hematology, biochemistry, microbiology, and
                      pathology. Equipped with cutting-edge technology, our
                      laboratory team ensures accurate and timely results. We
                      offer routine blood tests, urine analysis, culture tests,
                      and specialized diagnostic tests to aid in disease
                      detection and monitoring.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/administrative.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-2">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Radio Diagnostic Department</h3>
                    <p className="fst-italic">
                      The Radio Diagnostic Department offers comprehensive
                      imaging services, including X-rays, ultrasounds, CT scans,
                      and MRIs. Our team of radiologists and technicians use
                      state-of-the-art equipment to provide precise and detailed
                      imaging for accurate diagnosis.
                    </p>
                    <p>
                      The Radio Diagnostic Department offers comprehensive
                      imaging services to support accurate diagnosis and
                      treatment. Our advanced imaging modalities include digital
                      X-rays, ultrasounds, computed tomography (CT) scans, and
                      magnetic resonance imaging (MRI). Our radiologists and
                      technicians are skilled in interpreting images to provide
                      precise information that guides patient care.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/radiology-1.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                  </div>
              </div>
              <div className="tab-pane" id="departments-tab-3">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Antenatal Department</h3>
                    <p className="fst-italic">
                      Our Antenatal Department is dedicated to the health and
                      well-being of expectant mothers and their babies. We
                      provide comprehensive prenatal care, including routine
                      check-ups, screenings, and educational resources to ensure
                      a healthy pregnancy.
                    </p>
                    <p>
                      The Antenatal Department is dedicated to providing
                      exceptional care for expectant mothers. Our services
                      include regular prenatal check-ups, ultrasound screenings,
                      nutritional counseling, and management of high-risk
                      pregnancies. We offer educational classes on childbirth
                      preparation, breastfeeding, and newborn care to ensure a
                      healthy pregnancy and a smooth delivery.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/antenatal-3.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-4">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Postnatal Department</h3>
                    <p className="fst-italic">
                      The Postnatal Department offers continued care for mothers
                      and newborns after delivery. Our services include
                      post-delivery check-ups, breastfeeding support, and
                      guidance on newborn care to ensure a smooth transition to
                      parenthood.
                    </p>
                    <p>
                      Our Postnatal Department focuses on the health and
                      recovery of mothers and newborns after delivery. We
                      provide post-delivery check-ups, lactation support, and
                      guidance on infant care. Our team ensures that both mother
                      and baby receive the necessary care and support during the
                      critical postpartum period.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/postnatal.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-5">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Child Health Department</h3>
                    <p className="fst-italic">
                      The Child Health Department focuses on the physical,
                      emotional, and developmental needs of children. We provide
                      preventive care, immunizations, and treatment for acute
                      and chronic illnesses, ensuring your child's health and
                      well-being.
                    </p>
                    <p>
                      The Child Health Department is committed to the
                      comprehensive care of infants, children, and adolescents.
                      Our pediatricians provide preventive care, immunizations,
                      developmental screenings, and treatment for acute and
                      chronic illnesses. We focus on promoting healthy growth
                      and development through regular check-ups and health
                      education.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/child-health.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-6">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Surgical Department</h3>
                    <p className="fst-italic">
                      Our Surgical Department offers a wide range of surgical
                      procedures, from minor outpatient surgeries to major
                      operations. Our skilled surgeons and support staff are
                      committed to providing safe, effective, and compassionate
                      care.
                    </p>
                    <p>
                      The Surgical Department offers a wide range of surgical
                      services, from elective and emergency surgeries to
                      minimally invasive procedures. Our experienced surgeons
                      specialize in general surgery, orthopedics, ENT, and more.
                      We prioritize patient safety and recovery, using the
                      latest surgical techniques and post-operative care
                      protocols.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/surgical.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-7">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Family Health Department</h3>
                    <p className="fst-italic">
                      The Family Health Department provides comprehensive
                      healthcare services for individuals and families. Our
                      focus is on preventive care, routine check-ups, and
                      managing chronic conditions to promote long-term health
                      and wellness for all family members.
                    </p>
                    <p>
                      Our Family Health Department provides holistic care for
                      individuals and families across all ages. Services include
                      routine check-ups, chronic disease management, preventive
                      screenings, and health education. We emphasize a
                      patient-centered approach, tailoring care plans to meet
                      the unique needs of each family member.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/family-health-1.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-8">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Obstetrics and Gynecology Department</h3>
                    <p className="fst-italic">
                      Our Obstetrics and Gynecology Department offers
                      specialized care for women's reproductive health. Services
                      include prenatal and postnatal care, gynecological exams,
                      family planning, and treatment of reproductive system
                      disorders.
                    </p>
                    <p>
                      The Obstetrics and Gynecology Department offers
                      specialized care for women’s reproductive health. Services
                      include comprehensive prenatal and postnatal care,
                      gynecological exams, family planning, and treatment of
                      reproductive health issues. Our team is dedicated to
                      supporting women through all stages of life, from
                      adolescence to menopause and beyond.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/gynecology-1.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-9">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Immunization Department</h3>
                    <p className="fst-italic">
                      The Immunization Department provides vaccinations for
                      children, adults, and travelers. Our goal is to protect
                      the community from vaccine-preventable diseases through
                      timely and effective immunization programs.
                    </p>
                    <p>
                      The Immunization Department plays a critical role in
                      preventing infectious diseases through vaccinations. We
                      offer a full range of vaccines for children, adults, and
                      travelers, including seasonal flu shots and routine
                      childhood immunizations. Our team ensures that vaccines
                      are administered safely and effectively, providing
                      protection for the individual and the community.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/immunization.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-10">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Pathology Department</h3>
                    <p className="fst-italic">
                      Our Pathology Department conducts essential diagnostic
                      testing and analysis of bodily tissues and fluids.
                      Pathologists work closely with other departments to
                      diagnose diseases and guide treatment plans based on
                      laboratory findings.
                    </p>
                    <p>
                      Our Pathology Department conducts in-depth analysis of
                      bodily tissues and fluids to diagnose diseases and guide
                      treatment plans. Services include histopathology,
                      cytology, and autopsy services. Our pathologists
                      collaborate closely with clinicians to provide accurate
                      diagnoses and support ongoing patient care.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/pathology-1.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-11">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Mortuary Services Department</h3>
                    <p className="fst-italic">
                      The Mortuary Department provides respectful and
                      compassionate care for deceased individuals. Our services
                      include preparation and preservation of the body, and
                      support for families during the funeral planning process.
                    </p>
                    <p>
                      The Mortuary Department provides respectful and dignified
                      care for deceased individuals. We offer embalming,
                      preparation, and preservation services, ensuring that
                      loved ones are treated with the utmost respect. Our team
                      supports families during the funeral planning process,
                      providing a compassionate and serene environment.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/mortuary.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-12">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Community Medicine Department</h3>
                    <p className="fst-italic">
                      The Community Medicine Department focuses on the health
                      needs of our rural population. We provide preventive care,
                      health education, and outreach programs aimed at improving
                      the overall health and well-being of our community.
                    </p>
                    <p>
                      The Community Medicine Department focuses on improving the
                      health and well-being of our rural community. We conduct
                      health education programs, preventive screenings, and
                      outreach initiatives. Our goal is to address public health
                      challenges, promote healthy lifestyles, and ensure access
                      to essential healthcare services for all community
                      members.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/community-medicine-2.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-13">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Laboratory Department</h3>
                    <p className="fst-italic">
                      Our Laboratory Department provides essential diagnostic
                      testing and analysis, supporting accurate diagnosis and
                      treatment planning. Equipped with advanced technology, we
                      offer a wide range of tests from routine blood work to
                      specialized diagnostic procedures.
                    </p>
                    <p>
                      Our Laboratory Department is the backbone of our
                      diagnostic services, providing a wide array of tests
                      including hematology, biochemistry, microbiology, and
                      pathology. Equipped with cutting-edge technology, our
                      laboratory team ensures accurate and timely results. We
                      offer routine blood tests, urine analysis, culture tests,
                      and specialized diagnostic tests to aid in disease
                      detection and monitoring.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/laboratory.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="tab-pane" id="departments-tab-14">
                <div className="row">
                  {/* <div className="col-lg-8 details order-2 order-lg-1">
                    <h3>Laboratory Department</h3>
                    <p className="fst-italic">
                      Our Laboratory Department provides essential diagnostic
                      testing and analysis, supporting accurate diagnosis and
                      treatment planning. Equipped with advanced technology, we
                      offer a wide range of tests from routine blood work to
                      specialized diagnostic procedures.
                    </p>
                    <p>
                      Our Laboratory Department is the backbone of our
                      diagnostic services, providing a wide array of tests
                      including hematology, biochemistry, microbiology, and
                      pathology. Equipped with cutting-edge technology, our
                      laboratory team ensures accurate and timely results. We
                      offer routine blood tests, urine analysis, culture tests,
                      and specialized diagnostic tests to aid in disease
                      detection and monitoring.
                    </p>
                  </div> */}
                  <div className="text-center order-1 order-lg-2">
                    <img
                      src={require("../assets/img/departments/pharmacy.jpg")}
                      alt=""
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
                  
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Departments;
