import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/main.css";
import "../assets/js/main";
import GLightbox from "glightbox";
import { useState } from "react";
import FsLightbox from "fslightbox-react";

function About() {
  const [toggler, setToggler] = useState(false);
  return (
    <section id="about" className="about section">
      <div className="container">
        <div className="row gy-4 gx-5">
          <div
            className="col-lg-6 position-relative align-self-start"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <img
              src={require("../assets/img/about.png")}
              className="img-fluid"
              alt=""
            />
            <button
              // href="https://youtu.be/VTS_7_YMKm8?si=OEnxHSax4MnNSFf_"
              className="glightbox play-btn" onClick={() => setToggler(!toggler)}
            ></button>
            
          </div>
          <FsLightbox
				toggler={toggler}
				sources={[
					'https://youtu.be/VTS_7_YMKm8?si=OEnxHSax4MnNSFf_',
				]}
			/>
          <div
            className="col-lg-6 content"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <h3>About Us</h3>
            <p>
              Ogbeide Medical Centre is incorporated in Nigeria and registered
              with the Corporate Affairs Commission, a private facility that
              aims to make groundbreaking progress in the medical field by
              placing focus on person-centred healthcare.
            </p>
            <ul>
              <li>
                <i className="fa-solid fa-vial-circle-check"></i>
                <div>
                  <h5>Vision</h5>
                  <p>
                    To evolve as a benchmark in quality healthcare services,
                    delivered at all times by compassionate medical
                    professionals within our catchment area and to be renowned
                    as a centre of execellence in healthcare delivery.
                  </p>
                </div>
              </li>
              <li>
                <i className="fa-solid fa-pump-medical"></i>
                <div>
                  <h5>Quality Policy</h5>
                  <p>
                    To sustain and improve clinical outcomes, patient safety and
                    patient satisfaction without discrimination through
                    continual improvement of our processes, quality-oriented
                    services and creation of a culture of excellence within the
                    organization.
                  </p>
                </div>
              </li>
              {/* <li>
                <i className="fa-solid fa-heart-circle-xmark"></i>
                <div>
                  <h5>Voluptatem et qui exercitationem</h5>
                  <p>
                    Et velit et eos maiores est tempora et quos dolorem autem
                    tempora incidunt maxime veniam
                  </p>
                </div>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
