import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../assets/css/main.css';
import '../assets/js/main';
import { ChevronRight } from "react-bootstrap-icons";
import { useState } from 'react';

function Faq() {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleFaqClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    }

    return (
        <section id="faq" className="faq section">
            <div className="container section-title" data-aos="fade-up">
                <h2>Frequently Asked Questions</h2>
                {/* <p>Have questions about our services or how to access care? Visit our Frequently Asked Questions section for answers to common inquiries about appointments, services, insurance, and more. We're here to help you navigate your healthcare journey.</p> */}
            </div>

            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10" data-aos="fade-up" data-aos-delay="100">
                        <div className="faq-container">
                            {faqData.map((item, index) => (
                                <div
                                    key={index}
                                    className={`faq-item ${activeIndex === index ? 'faq-active' : ''}`}
                                    onClick={() => handleFaqClick(index)}
                                >
                                    <h3>{item.question}</h3>
                                    <div className="faq-content">
                                        <p>{item.answer}</p>
                                    </div>
                                    <i className="faq-toggle bi bi-chevron-right"><ChevronRight/></i>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

const faqData = [
    {
        question: "What are the hospital’s visiting hours?",
        answer: "Visiting hours vary by department. Please check the specific department's page or contact us for more information."
    },
    {
        question: "How do I make an appointment?",
        answer: "Appointments can be made by calling our main reception desk or through our online appointment booking system."
    },
    {
        question: "What insurance plans do you accept?",
        answer: "We accept a wide range of insurance plans. Please contact our billing department to verify if your insurance is accepted."
    },
    {
        question: "What types of medical services do you offer?",
        answer: "We offer a comprehensive range of services including general medical care, pediatric care, laboratory services, surgery, obstetric and gynecologic care, and more. Visit our services page for a complete list."
    },
    {
        question: "Do you provide emergency services?",
        answer: "Yes, our hospital has a fully equipped emergency department that operates 24/7."
    },
    {
        question: "What pediatric services are available?",
        answer: "Our Child Health Department offers preventive care, immunizations, developmental screenings, and treatment for various pediatric conditions."
    },
    {
        question: "What surgical procedures do you perform?",
        answer: "Our Surgical Department performs a variety of procedures, including general surgery, orthopedic surgery, ENT surgery, and more."
    },
    {
        question: "What support services do you offer for families?",
        answer: "We offer a range of support services including family health counseling, nutritional advice, and chronic disease management programs."
    }
];

export default Faq;
