import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/js/bootstrap.bundle'
import "../assets/css/main.css";
import "../assets/js/main";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUserDoctor,
  faHospital,
  faFlask,
  faAward,
} from "@fortawesome/free-solid-svg-icons";
import CountUp from "react-countup";

function Stats() {
  return (
    <section id="stats" className="stats section">
      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
            <i className="fa-solid fa-user-doctor">
              <FontAwesomeIcon icon={faUserDoctor} />
            </i>

            <div className="stats-item">
              <span
                data-purecounter-start="0"
                data-purecounter-end="85"
                data-purecounter-duration="1"
                className="purecounter"
              >
                {" "}
                <CountUp
                  start={0}
                  end={3}
                  duration={2.75}
                  separator=" "
                  decimals={0}
                  decimal=","
                  prefix=""
                  suffix=""
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
              </span>
              <p>Doctors</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
            <i className="fa-regular fa-hospital">
              <FontAwesomeIcon icon={faHospital} />
            </i>
            <div className="stats-item">
              <span
                data-purecounter-start="0"
                data-purecounter-end="18"
                data-purecounter-duration="1"
                className="purecounter"
              >
                {" "}
                <CountUp
                  start={0}
                  end={14}
                  duration={2.75}
                  separator=" "
                  decimals={0}
                  decimal=","
                  prefix=""
                  suffix=""
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
              </span>
              <p>Departments</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
            <i className="fas fa-flask">
              <FontAwesomeIcon icon={faFlask} />
            </i>
            <div className="stats-item">
              <span
                data-purecounter-start="0"
                data-purecounter-end="12"
                data-purecounter-duration="1"
                className="purecounter"
              >
                <CountUp
                  start={0}
                  end={1}
                  duration={2.75}
                  separator=" "
                  decimals={0}
                  decimal=","
                  prefix=""
                  suffix=""
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
              </span>
              <p>Research Lab</p>
            </div>
          </div>

          <div className="col-lg-3 col-md-6 d-flex flex-column align-items-center">
            <i className="fas fa-award">
              <FontAwesomeIcon icon={faAward} />
            </i>
            <div className="stats-item">
              <span
                data-purecounter-start="0"
                data-purecounter-end="150"
                data-purecounter-duration="1"
                className="purecounter"
              >
                <CountUp
                  start={0}
                  end={10}
                  duration={2.75}
                  separator=" "
                  decimals={0}
                  decimal=","
                  prefix=""
                  suffix=""
                  onEnd={() => console.log("Ended! 👏")}
                  onStart={() => console.log("Started! 💨")}
                ></CountUp>
              </span>
              <p>Awards</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Stats;
