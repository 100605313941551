
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import '../assets/css/main.css';
import '../assets/js/main'
import { TwitterX, Facebook, Instagram, Linkedin, ArrowUpShort } from "react-bootstrap-icons";

function Footer() {
  return (
<>
<footer id="footer" className="footer">

    <div className="container footer-top">
      <div className="row gy-4">
        <div className="col-lg-6 col-md-6 footer-about">
          <a href="index.html" className="logo d-flex align-items-center">
            <span className="sitename">Ogbeide Medical Centre</span>
          </a>
          <div className="footer-contact pt-3">
            <p>No 1, Ogbeide Medical Centre Avenue, <br/>Idumogho Quarters, Uhe, P. O. Box 406, Ubiaja, <br/>Esan South East LGA,</p>
            <p>Edo State, Nigeria</p>
            <p className="mt-3"><strong>Phone:</strong> <a href="tel:+2347049897403"><span>+234 704 989 7403</span></a></p>
            <p><strong>Email:</strong> <a href='mailto:doctor@ogbeidemedical.com'><span>doctor@ogbeidemedical.com</span></a></p>
          </div>
          {/* <div className="social-links d-flex mt-4">
            <a href=""><i className="bi bi-twitter-x"><TwitterX /></i></a>
            <a href=""><i className="bi bi-facebook"><Facebook /></i></a>
            <a href=""><i className="bi bi-instagram"><Instagram /></i></a>
            <a href=""><i className="bi bi-linkedin"><Linkedin /></i></a>
          </div> */}
        </div>

        <div className="col-lg-3 col-md-3 footer-links">
          <h4>Useful Links</h4>
          <ul>
            <li><a href="#">Home</a></li>
            <li><a href="#">About us</a></li>
            <li><a href="#">Services</a></li>
            <li><a href="#">Terms of service</a></li>
            <li><a href="#">Privacy policy</a></li>
          </ul>
        </div>

        <div className="col-lg-3 col-md-3 footer-links">
          <h4>Our Services</h4>
          <ul>
            <li><a href="#">General Paediatrics</a></li>
            <li><a href="#">Laboratory Services</a></li>
            <li><a href="#">Metabolic Disease/Diabetes Care</a></li>
            <li><a href="#">General Medical Care & Surgery</a></li>
            <li><a href="#">Mortuary Services</a></li>
          </ul>
        </div>

        {/* <div className="col-lg-4 col-md-3 footer-links">
          <h4>Departments</h4>
          <ul>
            <li><a href="#">Laboratory</a></li>
            <li><a href="#">Radio Diagnostic</a></li>
            <li><a href="#">Antenatal</a></li>
            <li><a href="#">Postnatal</a></li>
            <li><a href="#">Child Health</a></li>
            <li><a href="#">Community Medicine</a></li>
          </ul>
        </div>

        <div className="col-lg-2 col-md-3 footer-links">
          <h4>Departments</h4>
          <ul>
            <li><a href="#">Surgical</a></li>
            <li><a href="#">Family Health</a></li>
            <li><a href="#">Obstetrics and Gynecology</a></li>
            <li><a href="#">Immunization</a></li>
            <li><a href="#">Pathology</a></li>
            <li><a href="#">Mortuary</a></li>
          </ul>
        </div> */}

      </div>
    </div>

    <div className="container copyright text-center mt-4">
      <p>© <span>Copyright {new Date().getFullYear()}</span> <strong className="px-1 sitename">Ogbeide Medical Centre</strong> <span>All Rights Reserved</span></p>
      <div className="credits">
        {/* <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you've purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: [buy-url] --> */}
        Powered by <a href="https://www.gnotable.ng/">Gnotable Technologies</a>
        Template by <a href="https://bootstrapmade.com/">BootstrapMade</a>
      </div>
    </div>

  </footer>

  {/* <!-- Scroll Top --> */}
  {/* <a href="#" id="scroll-top" className="scroll-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"><ArrowUpShort/></i></a> */}
  

  {/* <!-- Preloader --> */}
  {/* <div id="preloader"></div> */}

  </>)}


export default Footer;