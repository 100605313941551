import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "../assets/css/main.css";
import "../assets/js/main";
import { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import moment from "moment";

function Appointment() {
  const form = useRef();
  const [emailSent, setEmailSent] = useState(false);
  const [emailFailed, setEmailFailed] = useState(false);
  const [open, setOpen] = useState(true);
  const [inputs, setInputs] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const contactParams = {
      from_name: inputs.name,
      from_email: inputs.email,
      appointment_date: moment(inputs.date).format('MMMM Do YYYY, h:mm A'),
      department: inputs.department,
      doctor: inputs.doctor,
      message: inputs.message,
      reply_to: inputs.email,
      phone: inputs.phone,
      subject: inputs.subject,
    };

    emailjs
      .send(
        "service_0nbe08u",
        "template_q6lpiym",
        contactParams,
        "Ygq8wXTHKCNxYTrAL"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setEmailSent(true);
          setEmailFailed(false);
          setOpen(true);
          form.current.reset();
          setInputs({});
        },
        (err) => {
          console.log("FAILED...", err);
          setEmailSent(false);
          setEmailFailed(true);
          setOpen(true);
        }
      );
  };

  return (
    <section id="appointment" className="appointment section">
      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Appointment</h2>
        <p>
          Schedule an appointment with us, whether you need a routine check-up, a specialist consultation, or follow-up care. We are committed to providing timely and efficient care to all our patients.
        </p>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <form
          ref={form}
          onSubmit={handleSubmit}
          method="post"
          
          className="php-email-form"
        >
          <div className="row">
            <div className="col-md-4 form-group">
              <input
                type="text"
                name="name"
                className="form-control"
                id="name"
                placeholder="Your Name"
                value={inputs.name || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 form-group mt-3 mt-md-0">
              <input
                type="email"
                className="form-control"
                name="email"
                id="email"
                placeholder="Your Email"
                value={inputs.email || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 form-group mt-3 mt-md-0">
              <input
                type="tel"
                className="form-control"
                name="phone"
                id="phone"
                placeholder="Your Phone"
                value={inputs.phone || ""}
                onChange={handleChange}
                required
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 form-group mt-3">
              <input
                type="datetime-local"
                name="date"
                className="form-control datepicker"
                id="date"
                placeholder="Appointment Date"
                value={inputs.date || ""}
                onChange={handleChange}
                required
              />
            </div>
            <div className="col-md-4 form-group mt-3">
              <select
                name="department"
                id="department"
                className="form-select"
                value={inputs.department || ""}
                onChange={handleChange}
                required
              >
                <option value="">Select Department</option>
                <option value="Antenatal">Antenatal</option>
                <option value="Postnatal">Postnatal</option>
                <option value="Child Health">Child Health</option>
                <option value="Family Health">Family Health</option>
                <option value="Obstetrics and Gynecology">Obstetrics and Gynecology</option>
                <option value="Community Medicine">Community Medicine</option>
              </select>
            </div>
            <div className="col-md-4 form-group mt-3">
              <select
                name="doctor"
                id="doctor"
                className="form-select"
                value={inputs.doctor || ""}
                onChange={handleChange}
                required
              >
                <option value="">Select Doctor</option>
                <option value="John Ogbeide">John Ogbeide</option>
                <option value="Elton-John Ogbeide Idahosa">Elton-John Ogbeide Idahosa</option>
                <option value="Sophia Ogbeide Osadebamwen">Sophia Ogbeide Osadebamwen</option>
                {/* Add more doctors here if needed */}
              </select>
            </div>
          </div>

          <div className="form-group mt-3">
            <textarea
              className="form-control"
              name="message"
              rows="5"
              placeholder="Message (Optional)"
              value={inputs.message || ""}
              onChange={handleChange}
            ></textarea>
          </div>
          <div className="mb-3">
            <div className="loading">Loading</div>
            
              {emailSent && (
                // <div className="sent-message">
                <Collapse in={open}>
                  <Alert
                    severity="success"
                    onClose={() => {
                      setOpen(false);
                    }}
                  >
                    Your appointment request has been sent successfully. Thank you!
                  </Alert>
                </Collapse>
                // </div>
              )}
              {emailFailed && (
                // <div className="error-message">
                <Collapse in={open}>
                  <Alert
                    severity="error"
                    onClose={() => {
                      setOpen(false);
                    }}
                  >
                    Failed to send your appointment request. Please try again later.
                  </Alert>
                </Collapse>
                // </div>
              )}
           
          </div>
          <div className="text-center">
            <button type="submit">Make an Appointment</button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Appointment;
