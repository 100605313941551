import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle'
import '../assets/css/main.css';
import '../assets/js/main'
import { Twitter, Facebook, Instagram, Linkedin, TwitterX } from "react-bootstrap-icons";

function Doctors() {
  return (
<section id="doctors" className="doctors section">

      {/* <!-- Section Title --> */}
      <div className="container section-title" data-aos="fade-up">
        <h2>Meet our Team</h2>
        <p>Meet our team of experienced and compassionate doctors who are dedicated to providing exceptional healthcare. Our medical professionals come from diverse specialties and are committed to delivering personalized and effective treatment to every patient.</p>
      </div>

      <div className="container">

        <div className="row gy-4">

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <div className="team-member d-flex align-items-start">
              <div className="pic"><img src={require("../assets/img/doctors/john-ogbeide-profile.jpg")} className="img-fluid" alt=""/></div>
              <div className="member-info">
                <h4>Dr. John Ekhator Ogbeide</h4>
                <span>CMD/CEO</span>
                {/* <p>Explicabo voluptatem mollitia et repellat qui dolorum quasi</p> */}
                {/* <div className="social">
                  <a href=""><i className="bi bi-twitter-x"><TwitterX /></i></a>
                  <a href=""><i className="bi bi-facebook"><Facebook/></i></a>
                  <a href=""><i className="bi bi-instagram"><Instagram/></i></a>
                  <a href="https://www.linkedin.com/in/john-ogbeide-a6ab7554/"> <i className="bi bi-linkedin"><Linkedin/></i> </a>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="200">
            <div className="team-member d-flex align-items-start">
              <div className="pic"><img src={require("../assets/img/doctors/elton-john-profile.jpg")} className="img-fluid" alt=""/></div>
              <div className="member-info">
                <h4>Dr. Elton-John Ogbeide Idahosa</h4>
                <span>Obstetrics and Gynecologist</span>
                {/* <p>Aut maiores voluptates amet et quis praesentium qui senda para</p> */}
                {/* <div className="social">
                <a href=""><i className="bi bi-twitter-x"><TwitterX /></i></a>
                  <a href=""><i className="bi bi-facebook"><Facebook/></i></a>
                  <a href=""><i className="bi bi-instagram"><Instagram/></i></a>
                  <a href=""> <i className="bi bi-linkedin"><Linkedin/></i> </a>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="300">
            <div className="team-member d-flex align-items-start">
              <div className="pic"><img src={require("../assets/img/doctors/sophia-profile.jpg")} className="img-fluid" alt=""/></div>
              <div className="member-info">
                <h4>Dr. Sophia Ogbeide Osadebamwen</h4>
                <span>Physician (General Medicine)</span>
                {/* <p>Quisquam facilis cum velit laborum corrupti fuga rerum quia</p> */}
                {/* <div className="social">
                <a href=""><i className="bi bi-twitter-x"><TwitterX /></i></a>
                  <a href=""><i className="bi bi-facebook"><Facebook/></i></a>
                  <a href=""><i className="bi bi-instagram"><Instagram/></i></a>
                  <a href=""> <i className="bi bi-linkedin"><Linkedin/></i> </a>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
            <div className="team-member d-flex align-items-start">
              <div className="pic"><img src={require("../assets/img/staff/beatrice-profile.jpg")} className="img-fluid" alt=""/></div>
              <div className="member-info">
                <h4>Mrs. Beatrice Ogbeide</h4>
                <span>Human Resource Manager</span>
                {/* <p>Dolorum tempora officiis odit laborum officiis et et accusamus</p>
                <div className="social">
                <a href=""><i className="bi bi-twitter-x"><TwitterX /></i></a>
                  <a href=""><i className="bi bi-facebook"><Facebook/></i></a>
                  <a href=""><i className="bi bi-instagram"><Instagram/></i></a>
                  <a href=""> <i className="bi bi-linkedin"><Linkedin/></i> </a>
                </div> */}
              </div>
            </div>
          </div>

          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
            <div className="team-member d-flex align-items-start">
              <div className="pic"><img src={require("../assets/img/staff/egbon-profile.jpg")} className="img-fluid" alt=""/></div>
              <div className="member-info">
                <h4>Mrs. Doris Egbon</h4>
                <span>Medical Administrator</span>
                {/* <p>Dolorum tempora officiis odit laborum officiis et et accusamus</p>
                <div className="social">
                <a href=""><i className="bi bi-twitter-x"><TwitterX /></i></a>
                  <a href=""><i className="bi bi-facebook"><Facebook/></i></a>
                  <a href=""><i className="bi bi-instagram"><Instagram/></i></a>
                  <a href=""> <i className="bi bi-linkedin"><Linkedin/></i> </a>
                </div> */}
              </div>
            </div>
          </div>
          
          <div className="col-lg-6" data-aos="fade-up" data-aos-delay="400">
            <div className="team-member d-flex align-items-start">
              <div className="pic"><img src={require("../assets/img/staff/okhilu-profile.jpg")} className="img-fluid" alt=""/></div>
              <div className="member-info">
                <h4>Emmanuel Okhilu</h4>
                <span>Laboratory Scientist</span>
                {/* <p>Dolorum tempora officiis odit laborum officiis et et accusamus</p>
                <div className="social">
                <a href=""><i className="bi bi-twitter-x"><TwitterX /></i></a>
                  <a href=""><i className="bi bi-facebook"><Facebook/></i></a>
                  <a href=""><i className="bi bi-instagram"><Instagram/></i></a>
                  <a href=""> <i className="bi bi-linkedin"><Linkedin/></i> </a>
                </div> */}
              </div>
            </div>
          </div>

        </div>

      </div>

    </section>)}

export default Doctors;